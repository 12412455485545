




import Vue from "vue";

export default Vue.extend({
  name: "LogoutView",
  metaInfo: {
    title: "Logout",
  },
  data: () => ({}),
  created() {
    for (const key in localStorage) {
      const item = localStorage.getItem(key);
      if (item) localStorage.removeItem(key);
    }
    for (const key in sessionStorage) {
      const item = sessionStorage.getItem(key);
      if (item) sessionStorage.removeItem(key);
    }
    setTimeout(() => {
      location.replace(process.env.VUE_APP_MARKETING_URL ?? location.href);
    }, 300);
  },
});
