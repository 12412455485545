












import Vue from "vue";
import { mapActions, mapMutations } from "vuex";
import { ApiException, UserProfileStatus } from "@/clients/clients";
import { GlobalMutations } from "@/store";
import AppPage from "@/components/layout/page/AppPage.vue";

export default Vue.extend({
  name: "TheAppMainLayout",
  components: { AppPage },
  data: () => ({
    errorMessage: "",
    loading: false,
  }),
  async created(): Promise<void> {
    this.loading = true;
    this.errorMessage = "";
    try {
      const user = await this.getUser();
      const newUser =
        user?.profileStatus === UserProfileStatus.Incomplete &&
        this.$route.name !== "AccountSetup";
      if (newUser) {
        await this.$router.push({ name: "AccountSetup" });
        return;
      }

      if (
        !user?.verified &&
        this.$route.meta &&
        this.$route.meta["requiresVerification"]
      ) {
        await this.$router.push({ name: "AccountSetupVerify" });
      }
    } catch (e) {
      this.errorMessage =
        (e as ApiException)?.message ?? "An unknown error occurred";
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapMutations({ setAppLoading: GlobalMutations.SET_APP_LOADING }),
    ...mapActions("UserStore", ["getUser"]),
  },
});
