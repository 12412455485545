


































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import AppPage from "@/components/layout/page/AppPage.vue";

export default Vue.extend({
  components: { AppPage },
});
