
























































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import AppLoading from "@/components/layout/AppLoading.vue";
import { ScriptPropertySrc, ScriptPropertySrcCallback } from "vue-meta/types/vue-meta";

declare var turnstile: any;

export default Vue.extend({
  name: "AppContactForm",
  data: () => ({
    name: "",
    email: "",
    message: "",
    token: "",
    loading: false,
    submitted: false,
    disabled: false,
    errors: "",
  }),
  methods: {
    ...mapActions(["setTurnstileCache"]),
    async onsubmit(): Promise<void> {
      try {
        this.loading = true;
        await fetch(process.env.VUE_APP_CONTACT_US_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: this.name,
            email: this.email,
            message: this.message,
            token: this.token,
          }),
        });
        this.submitted = true;
      } catch (e) {
        this.errors = "An error occurred. Please try again later.";
        this.disabled = true;
      } finally {
        this.loading = false;
      }
    },
    handleTokenCallback(token: any): void {
      this.token = token;
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["turnstileCache"]),
    validForm(): boolean | string {
      return this.name && this.email && this.message && this.token;
    },
  },
  mounted(): void {
    const turnstileElement = this.$refs.turnstile as HTMLElement;
    const render = {
      sitekey: process.env.VUE_APP_TURNSTILE_SITE_KEY,
      callback: this.handleTokenCallback,
      theme: "light",
    };
    turnstile.ready(() => {
      turnstile.render(`#${turnstileElement.id}`, render);
    });
  },
  deactivated(): void {
    this.name = "";
    this.errors = "";
    this.loading = false;
    this.submitted = false;
    this.disabled = false;
    this.token = "";
  },
});
