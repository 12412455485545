import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import {
  SharedReferenceResponse,
  UserAccountSetupBasicInformationCommand,
  UserAccountSetupWorkInformationCommand,
  UserAccountUpdateBasicInformationCommand,
  UserAccountUpdateEmailCommand,
  UserAccountUpdateGdprConsentCommand,
  UserAccountUpdateInterestsCommand,
  UserAccountUpdateWorkInformationCommand,
  UserAccountVerifyCommand,
  UserClient,
  UserFullProfileResponse,
  UserProfileStatus,
} from "@/clients/clients";
import { IAppState } from "@/store/index";
import { httpClientFactory } from "@/services/http/http";
import { getAccessToken } from "@/services/auth/aadAuthService";

export interface IUserState {
  user: UserFullProfileResponse;
}

export interface IUserGetters {
  id: (state: IUserState) => string;
  profileStatus: (state: IUserState) => UserProfileStatus;
  interests: (state: IUserState) => string[];
  user: (state: IUserState) => UserFullProfileResponse;
}

export enum UserMutations {
  SET_USER = "SET_USER",
}

const state: IUserState = {
  user: new UserFullProfileResponse(),
};

const getters: GetterTree<IUserState, IAppState> = {
  id: (state): string => state.user.id ?? "",
  profileStatus: (state): UserProfileStatus =>
    state.user.profileStatus ?? UserProfileStatus.Incomplete,
  interests: (state): string[] => state.user.interests ?? [],
  user: (state): UserFullProfileResponse => state.user,
  userGdprConsent: (state): boolean => state.user.gdprConsent ?? false,
  verified: (state): boolean => state.user?.verified ?? false,
  initials: (state): string =>
    [state?.user?.firstName, state?.user?.lastName]
      .filter((p) => p?.length)
      .map((n) => n?.toString().slice(0, 1))
      .join(""),
  webinars: (state): string[] =>
    state.user.webinars
      ?.map((p: SharedReferenceResponse): string => {
        return p.id?.length ? p.id : "";
      })
      .filter((p: string) => p.length > 0) ?? [],
};

const mutations: MutationTree<IUserState> = {
  [UserMutations.SET_USER](
    state: IUserState,
    payload: UserFullProfileResponse
  ) {
    state.user = Object.assign({}, state.user, payload);
  },
};

const actions: ActionTree<IUserState, IAppState> = {
  async getUser({ commit }): Promise<UserFullProfileResponse | null> {
    const accessToken: string = await getAccessToken();
    const client = httpClientFactory(UserClient, accessToken);
    const user = await client.get();
    commit(UserMutations.SET_USER, user);
    return user;
  },

  async accountSetupBasicInformation(
    { commit },
    payload: UserAccountSetupBasicInformationCommand
  ): Promise<UserFullProfileResponse | null> {
    const accessToken: string = await getAccessToken();
    const client = httpClientFactory(UserClient, accessToken);
    const user = await client.setupAccountProfile(payload);
    commit(UserMutations.SET_USER, user);
    return user;
  },

  async accountSetupWorkInformation(
    { commit },
    payload: UserAccountSetupWorkInformationCommand
  ): Promise<UserFullProfileResponse | null> {
    const accessToken: string = await getAccessToken();
    const client = httpClientFactory(UserClient, accessToken);
    const user = await client.setupAccountWorkInformation(payload);
    commit(UserMutations.SET_USER, user);
    return user;
  },

  async verifyAccount(
    { commit },
    payload: UserAccountVerifyCommand
  ): Promise<UserFullProfileResponse | null> {
    const accessToken: string = await getAccessToken();
    const client = httpClientFactory(UserClient, accessToken);
    const user = await client.verifyAccount(payload);
    commit(UserMutations.SET_USER, user);
    return user;
  },

  async updateBasicInformation(
    { commit },
    payload: UserAccountUpdateBasicInformationCommand
  ): Promise<UserFullProfileResponse> {
    const accessToken: string = await getAccessToken();
    const client = httpClientFactory(UserClient, accessToken);
    const user = await client.updateBasicInformation(payload);
    commit(UserMutations.SET_USER, user);
    return user;
  },

  async updateWorkInformation(
    { commit },
    payload: UserAccountUpdateWorkInformationCommand
  ): Promise<UserFullProfileResponse> {
    const accessToken: string = await getAccessToken();
    const client = httpClientFactory(UserClient, accessToken);
    const user = await client.updateWorkInformation(payload);
    commit(UserMutations.SET_USER, user);
    return user;
  },

  async updateInterests(
    { commit },
    payload: UserAccountUpdateInterestsCommand
  ): Promise<UserFullProfileResponse> {
    const accessToken: string = await getAccessToken();
    const client = httpClientFactory(UserClient, accessToken);
    const user = await client.updateInterests(payload);
    commit(UserMutations.SET_USER, user);
    return user;
  },

  async updateEmail(
    { commit },
    payload: UserAccountUpdateEmailCommand
  ): Promise<UserFullProfileResponse> {
    const accessToken: string = await getAccessToken();
    const client = httpClientFactory(UserClient, accessToken);
    const user = await client.updateEmail(payload);
    commit(UserMutations.SET_USER, user);
    return user;
  },

  async updateGdprConsent(
    { commit },
    payload: boolean
  ): Promise<UserFullProfileResponse> {
    const accessToken: string = await getAccessToken();
    const client = httpClientFactory(UserClient, accessToken);
    const user = await client.updateGdprConsent(
      new UserAccountUpdateGdprConsentCommand({ gdprConsent: payload })
    );
    commit(UserMutations.SET_USER, user);
    return user;
  },
};

const UserStore: Module<IUserState, IAppState> = {
  namespaced: true,
  state: () => state,
  mutations,
  actions,
  getters,
};

export default UserStore;
