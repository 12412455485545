





























































































import Vue from "vue";
import AppAccountMenuProfile from "@/components/accounts/navigation/AppAccountMenuProfile.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "TheAppHeader",
  components: { AppAccountMenuProfile },
  data: () => ({}),
  computed: {
    ...mapGetters("UserStore", ["initials"]),
  },
});
