










import Vue from "vue";
import AppLoadingSpinner from "@/components/indicators/AppLoadingSpinner.vue";

export default Vue.extend({
  name: "AppPageLoading",
  components: { AppLoadingSpinner },
  props: {
    title: {
      type: String,
      default: "Loading...",
    },
  },
  data: () => ({}),
});
