





































































import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "AppSiteHero",
  data: () => ({}),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("UserStore", ["user"]),
    name(): string {
      return this.user.firstName ?? "";
    },
    position(): string {
      return window.innerWidth < 960 ? "left center" : "top center";
    },
    year(): string {
      return new Date().getFullYear().toString();
    },
  },
});
