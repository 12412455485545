















import Vue from "vue";
import AppPage from "@/components/layout/page/AppPage.vue";

export default Vue.extend({
  name: "Error500View",
  metaInfo: {
    title: "Error",
  },
  components: { AppPage },
  data: () => ({}),
});
