import Vue from "vue";
import VueRouter from "vue-router";
import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  EndSessionRequest,
  InteractionStatus,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from "@azure/msal-browser";
import VueRouterNavigationClient from "@/services/auth/vueRouterNavigationClient";
import { loginRequest } from "@/configs/authConfig";

let instance: AadAuthService;

export const getAuthInstance = (): AadAuthService | null => instance ?? null;

export interface IMsalPluginConfig {
  msalConfig: Configuration;
  router?: VueRouter;
}

export interface IMsalAuthState {
  interactionStatus: InteractionStatus;
  activeAccount: AccountInfo | null;
  accountId: string;
  accounts: AccountInfo[];
  isNew: boolean;
  authenticated: boolean;
}

export interface IIdTokenClaims {
  [key: string]: string | string[] | number | boolean;
}

export interface ITokenStore {
  token: string;
  date: Date;
}

export enum TokenStoreType {
  Token = "userToken",
}

export async function getAccessToken(): Promise<string> {
  const authService = getAuthInstance();
  return (
    (await authService?.acquireTokenSilent(loginRequest))?.accessToken ?? ""
  );
}

export class AadAuthService {
  public state: IMsalAuthState;
  public authClient: PublicClientApplication;

  public get authenticated(): boolean {
    return this.state.authenticated;
  }

  public get activeAccount(): AccountInfo | null {
    return this.state?.activeAccount ?? null;
  }

  public get accountId(): string {
    return this.state?.accountId;
  }

  public get accounts(): AccountInfo[] {
    return this.state?.accounts;
  }

  public get isNew(): boolean {
    let newUser = false;
    if (this.state?.activeAccount) {
      const tokenClaims = this.state?.activeAccount
        .idTokenClaims as IIdTokenClaims;
      newUser = tokenClaims["newUser"] === true;
    }
    return newUser;
  }

  public get isAdmin(): boolean {
    let hasAdminClaim = false;
    if (this.state?.activeAccount) {
      const tokenClaims = this.state?.activeAccount
        .idTokenClaims as IIdTokenClaims;
      hasAdminClaim = tokenClaims["admin"] === true;
    }
    return hasAdminClaim;
  }

  public get verified(): boolean {
    let verified = false;
    if (this.state?.activeAccount) {
      const tokenClaims = this.state?.activeAccount
        .idTokenClaims as IIdTokenClaims;
      verified = tokenClaims["verified"] === true;
    }
    return verified;
  }

  public get blocked(): boolean {
    let verified = false;
    if (this.state?.activeAccount) {
      const tokenClaims = this.state?.activeAccount
        .idTokenClaims as IIdTokenClaims;
      verified = tokenClaims["blocked"] === true;
    }
    return verified;
  }

  constructor(msalConfig: Configuration, router?: VueRouter) {
    this.authClient = new PublicClientApplication(msalConfig);

    if (router) {
      this.authClient.setNavigationClient(
        new VueRouterNavigationClient(router)
      );
    }

    this.state = Vue.observable({
      interactionStatus: InteractionStatus.Startup,
      activeAccount: null,
      accountId: "",
      accounts: [],
      isNew: false,
      authenticated: false,
    });
  }

  public setActiveAccount(account: AccountInfo): void {
    this.authClient.setActiveAccount(account);
    this.state.activeAccount = this.authClient.getActiveAccount();
    this.state.accountId = this.state.activeAccount?.homeAccountId ?? "";
  }

  public async handleRedirectPromise(): Promise<AuthenticationResult | null> {
    return await this.authClient.handleRedirectPromise();
  }

  public handleRedirect(): void {
    this.updateActiveAccount();
    this.state.authenticated = true;
  }

  public async handleLogin(): Promise<AuthenticationResult | null> {
    const redirect = await this.handleRedirectPromise();
    if (redirect) this.handleRedirect();
    return redirect;
  }

  public updateActiveAccount(): void {
    this.state.accounts = this.authClient.getAllAccounts();
    this.setActiveAccount(this.state.accounts[0]);
  }

  public async isAuthenticated(): Promise<boolean> {
    try {
      const token = await this.acquireTokenSilent(loginRequest);
      this.updateActiveAccount();
      this.state.authenticated = token.account !== null;
      return this.authenticated;
    } catch (e) {
      this.state.authenticated = false;
      return this.authenticated;
    }
  }

  public async signInSilently(): Promise<AuthenticationResult> {
    const result = await this.authClient.ssoSilent(loginRequest);
    this.updateActiveAccount();
    this.state.authenticated = true;
    return result;
  }

  public async acquireTokenSilent(
    request: SilentRequest
  ): Promise<AuthenticationResult> {
    return await this.authClient.acquireTokenSilent(request);
  }

  public async loginRedirect(request?: RedirectRequest): Promise<void> {
    await this.authClient.handleRedirectPromise();
    await this.authClient.loginRedirect(request);
  }

  public async logout(logoutRequest?: EndSessionRequest): Promise<void> {
    const request = logoutRequest
      ? logoutRequest
      : {
          account: this.activeAccount,
          postLogoutRedirectUri: process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI,
        };
    await this.authClient.logoutRedirect(request);
  }
}

export const useAad = (config: IMsalPluginConfig): AadAuthService => {
  if (instance) return instance;
  const { msalConfig, router } = config;
  instance = new AadAuthService(msalConfig, router);

  return instance;
};
