





















































































import Vue from "vue";
import {
  WebinarAvailability,
  WebinarsSummaryPublicResponse,
} from "@/clients/clients";
import { mapGetters } from "vuex";
import defaultImage from "../../assets/fall-g3a2b20769_1920.jpg";

export default Vue.extend({
  name: "WebinarPromotionCard",
  props: {
    webinar: WebinarsSummaryPublicResponse,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["appLoading", "assetBaseUrl"]),
    webinarAvailability(): typeof WebinarAvailability {
      return WebinarAvailability;
    },
    promotionalImage(): string {
      return this.assetBaseUrl.length && this.webinar?.promotionalImageUri
        ? `${this.assetBaseUrl}/${this.webinar.promotionalImageUri}`
        : defaultImage;
    },
  },
});
