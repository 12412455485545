













































import Vue from "vue";
import TheAppHeader from "@/components/layout/TheAppHeader.vue";
import TheAppFooter from "@/components/layout/TheAppFooter.vue";
import TheAppMainLayout from "@/components/layout/TheAppMainLayout.vue";
import TheAppHeaderMobile from "@/components/layout/TheAppHeaderMobile.vue";
import AppNavigationDrawerLinks from "@/components/navigation/AppNavigationDrawerLinks.vue";
import SplashView from "@/views/SplashView.vue";
import TheAppBottomNavigation from "@/components/navigation/TheAppBottomNavgation.vue";
import { ScriptPropertyJson } from "vue-meta";
import {
  ScriptPropertySrc,
  ScriptPropertySrcCallback,
  ScriptPropertyText,
} from "vue-meta/types/vue-meta";
import AppContactForm from "@/components/forms/AppContactForm.vue";
import { tr } from "date-fns/locale";

const scripts: (
  | ScriptPropertyText
  | ScriptPropertySrc
  | ScriptPropertySrcCallback
  | ScriptPropertyJson
)[] = [];
if (process.env.NODE_ENV === "production") {
  scripts.push({
    src: "https://plausible.io/js/script.hash.js",
    defer: true,
    "data-domain": "portal.stadahcp.co.uk",
  });
}
export default Vue.extend({
  name: "App",
  metaInfo: {
    title: "Thornton & Ross",
    titleTemplate: "%s | Thornton & Ross",
    script: scripts,
  },
  components: {
    AppContactForm,
    TheAppBottomNavigation,
    SplashView,
    AppNavigationDrawerLinks,
    TheAppHeaderMobile,
    TheAppMainLayout,
    TheAppFooter,
    TheAppHeader,
  },
  data: () => ({
    drawer: false,
    help: false,
  }),
  computed: {
    authenticated(): boolean {
      return this.$auth.authenticated;
    },
    loginContinue(): boolean {
      return this.$router.currentRoute.name === "Login";
    },
    splash(): boolean {
      if (this.loginContinue) return false;
      return !this.authenticated;
    },
  },
});
