





























import Vue from "vue";
import { mapGetters } from "vuex";
import { RawLocation } from "vue-router";

export default Vue.extend({
  name: "AppSiteHeroFooter",
  data: () => ({}),
  computed: {
    ...mapGetters("UserStore", ["interests"]),
  },
  methods: {
    async selectTopic(topic: string): Promise<void> {
      const route: RawLocation = { name: "Webinars" };
      route.query = Object.assign({}, { topic: topic }, route.query);
      await this.$router.push(route);
    },
  },
});
