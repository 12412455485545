import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import Error500View from "@/views/error/Error500View.vue";
import { getAuthInstance } from "@/services/auth/aadAuthService";
import { loginRequest } from "@/configs/authConfig";
import LogoutView from "@/views/auth/LogoutView.vue";
import Error404View from "@/views/error/Error404View.vue";
import Error403View from "@/views/error/Error403View.vue";
import LoginView from "@/views/auth/LoginView.vue";
import { RedirectRequest } from "@azure/msal-browser";
import store from "@/store";
import PrivacyView from "@/views/PrivacyView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      requiresVerification: true,
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: PrivacyView,
    meta: {
      requiresVerification: true,
    },
  },
  {
    path: "/webinars",
    name: "Webinars",
    meta: {
      requiresVerification: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "webinars" */ "../views/webinar/WebinarCatalogueView.vue"
      ),
  },
  {
    path: "/webinars/collection",
    name: "WebinarCollection",
    meta: {
      requiresVerification: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "webinarCollection" */ "../views/webinar/WebinarCollectionView.vue"
      ),
  },
  {
    path: "/webinars/:id/enroll",
    name: "WebinarEnroll",
    meta: {
      requiresVerification: true,
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "webinar" */ "../views/webinar/WebinarRegistrationView.vue"
      ),
  },
  {
    path: "/webinars/:id",
    name: "Webinar",
    meta: {
      requiresVerification: true,
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "webinar" */ "../views/webinar/WebinarView.vue"
      ),
  },

  {
    path: "/certificates",
    name: "Certificates",
    meta: {
      requiresVerification: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "certificates" */ "../views/user/UserCertificatesView.vue"
      ),
  },
  {
    path: "/account",
    name: "Account",
    redirect: { name: "Profile" },
    component: () =>
      import(
        /* webpackChunkName: "account" */ "../views/account/AccountView.vue"
      ),
    children: [
      {
        path: "profile",
        name: "Profile",
        redirect: { name: "ProfileBasicInformation" },
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/account/ProfileView.vue"
          ),
        children: [
          {
            path: "/",
            name: "ProfileBasicInformation",
            component: () =>
              import(
                /* webpackChunkName: "profileBasicInformation" */ "../views/account/profile/ProfileBasicInformationView.vue"
              ),
          },
          {
            path: "work",
            name: "ProfileWorkInformation",
            component: () =>
              import(
                /* webpackChunkName: "profileWorkInformation" */ "../views/account/profile/ProfileWorkInformationView.vue"
              ),
          },
          {
            path: "verification",
            name: "ProfileVerificationDetails",
            component: () =>
              import(
                /* webpackChunkName: "profileVerificationDetails" */ "../views/account/profile/ProfileVerificationInformationView.vue"
              ),
          },
          {
            path: "email",
            name: "ProfileEmailInformation",
            component: () =>
              import(
                /* webpackChunkName: "profileEmailInformation" */ "../views/account/profile/ProfileEmailInformationView.vue"
              ),
          },
          {
            path: "settings/interests",
            name: "SettingsUserInterests",
            component: () =>
              import(
                /* webpackChunkName: "settingsUserInterests" */ "../views/account/settings/SettingsUserInterestsView.vue"
              ),
          },
        ],
      },
      {
        name: "AccountSetup",
        path: "setup",
        component: () =>
          import(
            /* webpackChunkName "accountSetup" */ "../views/account/setup/AccountSetupView.vue"
          ),
      },
      {
        name: "AccountSetupVerify",
        path: "setup/verify",
        component: () =>
          import(
            /* webpackChunkName "accountSetup" */ "../views/account/setup/AccountVerifyView.vue"
          ),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogoutView,
  },
  {
    path: "/unauthorised",
    name: "Unauthorised",
    component: Error403View,
  },
  {
    path: "/error",
    name: "Error",
    component: Error500View,
  },
  {
    path: "*",
    name: "Error404",
    component: Error404View,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (
    to.name === "Login" ||
    to.name === "Error" ||
    to.name === "Logout" ||
    to.name === "Unauthorised" ||
    to.name === "Privacy"
  ) {
    next();
    return;
  }

  const authService = getAuthInstance();

  if (!authService) {
    next({ name: "Error" });
    return;
  }

  await authService.isAuthenticated();

  if (!authService.authenticated) {
    const origin = JSON.stringify(to);
    const redirectRequest: RedirectRequest = { ...loginRequest, state: origin };
    await authService.loginRedirect(redirectRequest);
    return;
  }

  if (authService.blocked) {
    next({ name: "Unauthorised" });
    return;
  }
  const verified = store.getters["UserStore/verified"];
  if (verified) {
    next();
    return;
  } else if (
    !verified &&
    to.matched.some((record) => record.meta.requiresVerification)
  ) {
    next({ name: "AccountSetupVerify" });
    return;
  } else {
    next();
    return;
  }
});

export default router;
