




























import Vue, { PropType } from "vue";
import AppPageBanner from "@/components/layout/page/AppPageBanner.vue";
import AppErrorNotice from "@/components/indicators/AppErrorNotice.vue";
import AppPageError from "@/components/layout/page/AppPageError.vue";
import AppPageContent from "@/components/layout/page/AppPageContent.vue";
import AppPageLoading from "@/components/layout/page/AppPageLoading.vue";

export default Vue.extend({
  name: "AppPage",
  components: {
    AppPageLoading,
    AppPageContent,
    AppPageError,
    AppErrorNotice,
    AppPageBanner,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
});
