






















































































import Vue from "vue";
import { mapGetters } from "vuex";
import AppAccountLinksNav from "@/components/accounts/navigation/AppAccountLinksNav.vue";

export default Vue.extend({
  name: "AppNavigationDrawerLinks",
  components: { AppAccountLinksNav },
  data: () => ({}),
  computed: {
    ...mapGetters("UserStore", ["user", "initials"]),
  },
  methods: {
    async signOut(): Promise<void> {
      await this.$auth.logout();
    },
  },
});
