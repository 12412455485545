




import Vue from "vue";
import { mapActions } from "vuex";
import { UserProfileStatus } from "@/clients/clients";
import { RawLocation } from "vue-router";
import AppPage from "@/components/layout/page/AppPage.vue";
import { TokenStoreType } from "@/services/auth/aadAuthService";

export default Vue.extend({
  name: "LoginView",
  components: { AppPage },
  data: () => ({
    error: "",
  }),
  async created() {
    const redirect = await this.$auth.handleRedirectPromise();
    if (redirect) {
      this.$auth.handleRedirect();
      const user = await this.getUser();
      const newUser = user?.profileStatus === UserProfileStatus.Incomplete;
      if (newUser) {
        await this.$router.replace({ name: "AccountSetup" });
        return;
      }
      if (!user?.verified) {
        await this.$router.replace({ name: "AccountSetupVerify" });
        return;
      }

      let next: RawLocation = { name: "Home" };

      const state = redirect.state ? JSON.parse(redirect.state) : null;
      if (
        state &&
        (Object.prototype.hasOwnProperty.call(state, "path") ||
          Object.prototype.hasOwnProperty.call(state, "name"))
      ) {
        next = { ...state };
      }

      await this.$router.replace(next);
      return;
    }

    await this.$router.replace({ name: "Unauthorised" });
  },
  methods: {
    ...mapActions("UserStore", ["getUser"]),
  },
});
