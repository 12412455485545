import Vue from "vue";
import "@mdi/font/css/materialdesignicons.css";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#004cac",
        secondary: "#00267c",
        accent: "#cf035c",
        error: "#B00020",
        info: "#039be5",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
});
