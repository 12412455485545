

















import Vue from "vue";
import AppPage from "@/components/layout/page/AppPage.vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "Error403View",
  metaInfo: {
    title: "Unauthorised",
  },
  components: { AppPage },
  data: () => ({
    loading: false,
    error: "",
  }),
  computed: {
    ...mapGetters("UserStore", ["user"]),
  },
  async created() {
    this.loading = true;
    if (this.user?.id) {
      await this.$router.replace({ name: "Home" });
      return;
    }
    try {
      await this.getUser();
      await this.$router.replace({ name: "Home" });
      return;
    } catch (e) {
      this.error = `${(e as Error).name}: ${(e as Error).message}`;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("UserStore", ["getUser"]),
  },
});
