import Vue from "vue";
import Vuex from "vuex";
import UserStore from "@/store/UserStore";
import { AssetBaseUrl } from "@/providers/BaseUrls";

Vue.use(Vuex);

export interface IAppState {
  appLoading: boolean;
  assetBaseUrl: string;
}

export enum GlobalMutations {
  SET_APP_LOADING = "SET_APP_LOADING",
}

const state: IAppState = {
  appLoading: false,
  assetBaseUrl: AssetBaseUrl ?? "",
};
export default new Vuex.Store({
  state,
  mutations: {
    [GlobalMutations.SET_APP_LOADING](
      state: IAppState,
      payload: boolean
    ): void {
      state.appLoading = payload;
    },
  },
  getters: {
    appLoading: (state: IAppState): boolean => state.appLoading,
    assetBaseUrl: (state: IAppState): string => state.assetBaseUrl,
  },
  actions: {},
  modules: {
    UserStore,
  },
});
