












































































import Vue from "vue";
import AppSiteHero from "@/components/layout/site-hero/AppSiteHero.vue";
import AppPage from "@/components/layout/page/AppPage.vue";
import AppSiteHeroFooter from "@/components/layout/site-hero/AppSiteHeroFooter.vue";
import { mapGetters, mapMutations } from "vuex";
import { GlobalMutations } from "@/store";
import {
  ApiException,
  SpeakerNamePublicResponse,
  WebinarsClient,
  WebinarsSummaryPublicResponse,
  WebinarsUserClient,
} from "@/clients/clients";
import WebinarPromotionCard from "@/components/webinars/WebinarPromotionCard.vue";
import { loginRequest } from "@/configs/authConfig";

export default Vue.extend({
  name: "HomeView",
  metaInfo: {
    title: "Home",
  },
  components: {
    WebinarPromotionCard,
    AppSiteHeroFooter,
    AppPage,
    AppSiteHero,
  },
  data: () => ({
    loading: false,
    auth: false,
    featured: [] as WebinarsSummaryPublicResponse[],
    recommended: [] as WebinarsSummaryPublicResponse[],
    collection: [] as WebinarsSummaryPublicResponse[],
    errorMessages: [] as string[],
  }),
  computed: {
    ...mapGetters("UserStore", ["interests", "webinars"]),
    ...mapGetters(["assetBaseUrl"]),
  },
  async created(): Promise<void> {
    this.loading = true;
    this.errorMessages = [];
    const token = await this.$auth.acquireTokenSilent(loginRequest);
    const client = this.$httpClientFactory(WebinarsClient, token.accessToken);
    const userClient = this.$httpClientFactory(
      WebinarsUserClient,
      token.accessToken
    );

    const getCollection = userClient
      .batch(this.webinars)
      .then((result) => (this.collection = result.webinars ?? []))
      .catch((e) => this.errorMessages.push((e as ApiException).message));
    const getFeatured = client
      .webinars(undefined, undefined, true, 0, 3)
      .then((result) => (this.featured = result.webinars ?? []))
      .catch((e) => this.errorMessages.push((e as ApiException).message));
    const getRecommended = client
      .webinars(this.interests, undefined, undefined, 0, 3)
      .then((result) => (this.recommended = result.webinars ?? []))
      .catch((e) => this.errorMessages.push((e as ApiException).message));
    try {
      await Promise.allSettled([getCollection, getFeatured, getRecommended]);
    } catch (e) {
      this.errorMessages.push((e as ApiException).message);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapMutations({ setLoadingStatus: GlobalMutations.SET_APP_LOADING }),
    formatSpeakerNames(speakers: SpeakerNamePublicResponse[]): string {
      return speakers
        .map((speaker) => {
          return [speaker.title, speaker.fullName]
            .filter((s) => s?.length)
            .join(" ");
        })
        .join(" ");
    },
  },
});
