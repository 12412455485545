








































import Vue from "vue";

export default Vue.extend({
  name: "AppAccountMenuProfile",
  data: () => ({}),
  methods: {
    async signOut(): Promise<void> {
      await this.$auth.logout();
    },
  },
});
