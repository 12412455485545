import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { AadPlugin } from "@/plugins/aadPlugin";
import { AxiosPlugin } from "@/plugins/axios";
import { msalConfig } from "@/configs/authConfig";
import VueMeta from "vue-meta";

import "@/styles/styles.scss";
import "add-to-calendar-button/assets/css/atcb.min.css";

Vue.config.productionTip = false;

Vue.use(AadPlugin, { msalConfig: msalConfig, router: router });
Vue.use(AxiosPlugin);
Vue.use(VueMeta);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
